<template>
  <div class="flex flex-col-reverse md:flex-row flex-wrap items-center">
    <a-button
      v-if="chats === null || chats.length === 0"
      icon="plus"
      type="primary"
      class="relative"
      :loading="addChatLoading"
      @click="addChat"
    >
      Добавить чат
    </a-button>
    <div class="flex flex-col">
      <div
        v-for="(chat, chatIndex) in chats"
        :key="chat.group_title + chatIndex"
        class="language-card"
        :class="{ 'ml-4': chatIndex, 'language-card_active': chat === selectedChat  }"
        :title="chat.group_title"
        @click="selectedChat = chat"
      >
        <selected-icon
          class="selected-icon"
          :active="chat === selectedChat"
        />
        <div class="flex flex-row items-center">
          <a-avatar
            :src="chat.group_photo"
            :alt="chat.group_title"
          />
          <p class="font-bold ml-2">
            {{ chat.group_title }}
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SelectedIcon from '@/components/chat-adding/SelectedIcon'

// chat_id:-467781265
// group_code:""
// group_description:""
// group_photo:"https://cabinet.chatkeeper.info/avatar/chat/chat-467781265.jpeg"
// group_title:"Рабочая 2"
// member_count:null

export default {
  name: "SelectChat",

  props: [ 'value', 'chats' ],

  components: {
    SelectedIcon
  },

  data() {
    return {
      addChatLoading: false
    }
  },

  methods: {
    addChat() {
      this.addChatLoading = true
      this.$emit('addChat', { closeLoading: this.closeLoading })
    },

    closeLoading() {
      this.addChatLoading = false
    },
  },

  computed: {
    selectedChat: {
      get() {
        return this.value
      },
      set(chat) {
        this.$emit('input', chat)
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import 'styles';
</style>
