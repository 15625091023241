<template>
  <div
    class="selected-icon"
    :class="{ 'selected-icon_active': active, 'selected-icon_disabled': !active }"
  >
    <i class="material-icons">
      check
    </i>
  </div>
</template>

<script>
export default {
  name: "SelectedIcon",

  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.selected-icon {
  background: rgba(var(--a-primary), 1);
  padding: 5px;
  font-size: 0;
  border-radius: 100%;
  transition: opacity .1s cubic-bezier(0.4, 0.0, 0.2, 1);

  &_active {
    opacity: 1;
  }

  &_disabled {
    opacity: 0;
  }

  i {
    font-size: 8px;
    color: #fff;
  }
}
</style>
